jQuery(document).ready(function($){

    function reverseString(str) {
        var arrayStrings = str.split("");
        var reverseArray = arrayStrings.reverse();
        var joinArray = reverseArray.join("");
        return joinArray;
    }

    $('[data-show]').click(function() {
        var $el = $('[data-phone]');
        var phone = reverseString($el.data('phone'));
        $el.text(phone);
        $el.attr('href', 'tel:' + phone.replace(' ', ''));
    });

    $('#modal-video').on('open.zf.reveal', function(event) {
        var video = $(this).find('video')[0];

        if (video.paused) {
            video.play();
        }
    });

    $('#modal-video').on('closed.zf.reveal', function(event) {
        var video = $(this).find('video')[0];

        if (!video.paused) {
            video.pause();
        }
    });

});
